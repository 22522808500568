import { countBy } from "lodash";
import { RuleData, RuleOutput } from ".";

export function idsAreUnique({ featureCollection }: RuleData): RuleOutput {
  const counts = countBy(
    featureCollection.features.filter((feature) => !!feature.id),
    (feature) => feature.id
  );

  const errors = Object.entries(counts)
    .filter(([, count]) => count > 1)
    .map(
      ([id, count]) =>
        `The ID ${id} is shared between ${count} features. The specification does not allow sharing IDs.`
    );

  return { errors };
}
