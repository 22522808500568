import { RuleData, RuleOutput } from ".";
import { visit } from "../visit";

export function propertiesTypeValid({
  featureCollection,
}: RuleData): RuleOutput {
  const errors: string[] = [];
  visit(featureCollection, {
    feature: (feature) => {
      const type = feature.properties?.type;
      if (!feature.properties?.type) {
        return;
      }
      if (!supportedTypes.includes(type)) {
        errors.push(
          `Invalid type ${type} in feature.properties.type is not recognized`
        );
      }
    },
  });

  return { errors };
}

const supportedTypes = [
  "area",
  "area.floor",
  "area.group",
  "area.room",
  "area.room.bathroom",
  "area.room.conference",
  "area.hallway",
  "area.section",
  "area.inaccessible",

  "fixture.door",
  "fixture.elevator",
  "fixture.stairs",
  "fixture.step",
  "fixture.wall",
  "fixture.wall.partition",
  "fixture.tv",

  "furniture.chair",
  "furniture.desk",
  "furniture.counter",
  "furniture.table",

  "poi.printer",
  "poi.entrance",

  "poi.device.sensor",
];
