import { Box, Button, FormControl, FormLabel } from "@chakra-ui/react";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

export function JSONUpload({ onChange }: { onChange: (data: string) => void }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: useCallback(
      async (acceptedFiles) => {
        if (!acceptedFiles) {
          return;
        }
        for (const file of acceptedFiles) {
          onChange(await readFileAsString(file));
        }
      },
      [onChange]
    ),
    accept: "application/json,.json,.geojson",
  });

  return (
    <FormControl {...getRootProps()}>
      <FormLabel>GeoJSON File (.json or .geojson, UTF-8)</FormLabel>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Box
          borderRadius="md"
          p={2}
          borderWidth={3}
          borderStyle="dashed"
          borderColor="gray.200"
        >
          Drop a GeoJSON file here
        </Box>
      ) : (
        <Button>Select file or drag one here</Button>
      )}
    </FormControl>
  );
}

function readFileAsString(file: File) {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target && typeof event.target.result === "string") {
        resolve(event.target.result);
      }
    };
    reader.readAsText(file, "utf-8");
  });
}
