import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { FeatureCollection } from "geojson";
import { countBy } from "lodash";
import { useMemo } from "react";

export function FeatureTypeTable({
  featureCollection,
}: {
  featureCollection: FeatureCollection;
}) {
  const byType = useMemo(() => {
    const features = featureCollection?.features;
    if (!features) {
      return null;
    }
    return countBy(features, (feature) => feature.properties?.type);
  }, [featureCollection]);

  if (!byType) {
    return null;
  }

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th>GeoJSON type</Th>
          <Th isNumeric>Number of features</Th>
        </Tr>
      </Thead>
      <Tbody>
        {Object.entries(byType).map(([type, count]) => (
          <Tr key={type}>
            <Td>{type}</Td>
            <Td isNumeric>{count}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
