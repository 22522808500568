import polygonize from "@turf/polygonize";
import {
  Geometry,
  GeometryCollection,
  LineString,
  MultiLineString,
} from "geojson";

export function fixPythagorasGeometry(geometry: Geometry): Geometry {
  switch (geometry.type) {
    case "LineString":
    case "MultiLineString":
      return fixLineStringGeometry(geometry);
    case "GeometryCollection":
      return {
        ...geometry,
        geometries: geometry.geometries.reduce((doneGeometries, geometry) => {
          const fixed = fixPythagorasGeometry(geometry);
          if (fixed.type === "GeometryCollection") {
            return [...doneGeometries, ...fixed.geometries];
          }
          return [...doneGeometries, fixed];
        }, [] as Geometry[]),
      };
    default:
      return geometry;
  }
}

function fixLineStringGeometry(
  geometry: LineString | MultiLineString
): GeometryCollection | LineString | MultiLineString {
  try {
    return {
      type: "GeometryCollection",
      geometries: polygonize(geometry)
        .features.map((feature) => feature.geometry)
        .filter(function <T>(x: T | null): x is T {
          return !!x;
        }),
    };
  } catch {
    return geometry;
  }
}
