import { RuleData, RuleOutput } from ".";
import { visit } from "../visit";

export function propertiesTypeExists({
  featureCollection,
}: RuleData): RuleOutput {
  const errors: string[] = [];
  visit(featureCollection, {
    feature: (feature) => {
      if (!feature.properties?.type) {
        if (feature.id) {
          errors.push(
            `Feature with ID ${feature.id} does not have a "type" field in the "properties" object. Yanzi cannot know how to draw this Feature.`
          );
        } else {
          errors.push(
            `Feature without ID does not have a "type" field in the "properties" object. Yanzi cannot know how to draw this Feature.`
          );
        }
      }
    },
  });

  return { errors };
}
