import { RuleData, RuleOutput } from ".";

export function namesShouldNotBeEmptyStrings({
  featureCollection,
}: RuleData): RuleOutput {
  const errors = featureCollection.features
    .filter((feature) => feature.properties?.name?.trim?.() === "")
    .map(
      (feature) =>
        `Feature with ID "${feature.id}" has a name property but the value is an empty string. Either remove the property completely or assign a name.`
    );
  return {
    errors,
  };
}
