import { RuleData, RuleOutput } from ".";

export function idsShouldNotBeEmptyStrings({
  featureCollection,
}: RuleData): RuleOutput {
  return {
    errors: featureCollection.features
      .filter((feature) => feature.id === "")
      .map(
        (feature) =>
          `A feature has an ID that is an empty string. Either remove the the ID completely or assign a real ID.`
      ),
  };
}
