import { RuleData, RuleOutput } from ".";
import { visit } from "../visit";

export function poiIsPoint({ featureCollection }: RuleData): RuleOutput {
  const errors: string[] = [];
  visit(featureCollection, {
    feature: (feature) => {
      const type = feature.properties?.type;
      if (!type || typeof type !== "string") {
        return;
      }
      if (!type.startsWith("poi")) {
        return;
      }
      if (feature.geometry.type !== "Point") {
        if (feature.id) {
          errors.push(
            `Feature with ID ${feature.id} is Point of interest (poi.*) but its geometry is not Point`
          );
        } else {
          errors.push(
            `Feature without ID is Point of interest (poi.*) but its geometry is not Point`
          );
        }
      }
    },
  });

  return { errors };
}
