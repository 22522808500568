import { isLeft } from "fp-ts/lib/Either";
import { FeatureCollection } from "geojson";
import * as t from "io-ts";
import reporter from "io-ts-reporters";

export function parseFeatureCollection(
  data: unknown,
  strictParsing?: boolean
): { data: FeatureCollection } | { errors: string[] } {
  if (strictParsing === false) {
    return { data } as { data: FeatureCollection };
  }
  const parsed = geoJsonFeatureCollection.decode(data);
  if (isLeft(parsed)) {
    return { errors: reporter.report(parsed) };
  }
  return { data: parsed.right };
}

const position = t.array(t.number); // t.tuple([t.number, t.number]);

const polygon = t.type({
  type: t.literal("Polygon"),
  coordinates: t.array(t.array(position)),
});

const multiPolygon = t.type({
  type: t.literal("MultiPolygon"),
  coordinates: t.array(t.array(t.array(position))),
});

const lineString = t.type({
  type: t.literal("LineString"),
  coordinates: t.array(position),
});

const multiLineString = t.type({
  type: t.literal("MultiLineString"),
  coordinates: t.array(t.array(position)),
});

const point = t.type({
  type: t.literal("Point"),
  coordinates: position,
});

const geometryCollection = t.type({
  type: t.literal("GeometryCollection"),
  geometries: t.array(
    t.union([point, multiLineString, lineString, multiPolygon, polygon])
  ),
});

const geometry = t.union([
  multiPolygon,
  polygon,
  lineString,
  multiLineString,
  point,
  geometryCollection,
]);

const feature = t.intersection([
  t.type({
    type: t.literal("Feature"),
    geometry: geometry,
    properties: t.any,
  }),
  t.partial({}),
]);

const geoJsonFeatureCollection = t.type({
  type: t.literal("FeatureCollection"),
  features: t.array(feature),
});
