import {
  Feature,
  FeatureCollection,
  Geometry,
  GeometryCollection,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
  Position,
} from "geojson";
import { isNotNull } from "./is-not-null";

type Visitor<T> = (part: T) => T | null | void;
type Visitors = {
  featureCollection: Visitor<FeatureCollection>;
  feature: Visitor<Feature>;
  geometry: Visitor<Geometry>;
  geometryCollection: Visitor<GeometryCollection>;
  lineString: Visitor<LineString>;
  multiLineString: Visitor<MultiLineString>;
  multiPoint: Visitor<MultiPoint>;
  multiPolygon: Visitor<MultiPolygon>;
  point: Visitor<Point>;
  polygon: Visitor<Polygon>;
  position: Visitor<Position>;
};

const defaultVisitor = () => {};

const defaultVisitors: Visitors = {
  featureCollection: defaultVisitor,
  feature: defaultVisitor,
  geometry: defaultVisitor,
  geometryCollection: defaultVisitor,
  lineString: defaultVisitor,
  multiLineString: defaultVisitor,
  multiPoint: defaultVisitor,
  multiPolygon: defaultVisitor,
  point: defaultVisitor,
  polygon: defaultVisitor,
  position: defaultVisitor,
};

export function visit(
  featureCollection: FeatureCollection,
  visitors: Partial<Visitors>
) {
  return visitFeatureCollection(featureCollection, {
    ...defaultVisitors,
    ...visitors,
  });
}

function visitFeatureCollection(
  featureCollection: FeatureCollection,
  visitors: Visitors
) {
  const newFeatureCollection = {
    ...featureCollection,
    features: visitFeatures(featureCollection.features, visitors),
  };
  const visited = visitors.featureCollection(newFeatureCollection);
  if (visited === null) {
    return null;
  }
  if (!visited) {
    return featureCollection;
  }
  return visited;
}

function visitFeatures(features: Feature[], visitors: Visitors) {
  return features
    .map((feature) => visitFeature(feature, visitors))
    .filter(isNotNull);
}

function visitFeature(feature: Feature, visitors: Visitors): Feature | null {
  const geometry = visitGeometry(feature.geometry, visitors);
  if (!geometry) {
    return null;
  }
  const newFeature = { ...feature, geometry };
  const visited = visitors.feature(newFeature);
  if (visited === null) {
    return null;
  }
  if (!visited) {
    return feature;
  }
  return visited;
}

function visitGeometry(geometry: Geometry, visitors: Visitors) {
  const visitedGeometry = visitors.geometry(geometry);
  if (!visitedGeometry) {
    return null;
  }

  const geom = visitedGeometry ?? geometry;

  switch (geom.type) {
    case "GeometryCollection":
      return visitGeometryCollection(geom, visitors);
    case "LineString":
      return visitLineString(geom, visitors);
    case "MultiLineString":
      return visitMultiLineString(geom, visitors);
    case "MultiPoint":
      return visitMultiPoint(geom, visitors);
    case "MultiPolygon":
      return visitMultiPolygon(geom, visitors);
    case "Polygon":
      return visitPolygon(geom, visitors);
    case "Point":
      return visitPoint(geom, visitors);
  }
}

function visitGeometryCollection(
  geometryCollection: GeometryCollection,
  visitors: Visitors
) {
  const visitedGeometries: Geometry[] = geometryCollection.geometries
    .map((geometry) => visitGeometry(geometry, visitors))
    .filter(isNotNull);

  const newGeometryCollection = {
    ...geometryCollection,
    geometries: visitedGeometries,
  };

  const visitedGeometryCollection = visitors.geometryCollection(
    newGeometryCollection
  );
  if (visitedGeometryCollection === null) {
    return null;
  }
  if (!visitedGeometryCollection) {
    return newGeometryCollection;
  }
  return visitedGeometryCollection;
}

function visitLineString(lineString: LineString, visitors: Visitors) {
  const newLineString = {
    ...lineString,
    coordinates: lineString.coordinates
      .map((position) => visitPosition(position, visitors))
      .filter(isNotNull),
  };
  const visited = visitors.lineString(newLineString);
  if (visited === null) {
    return null;
  }
  if (!visited) {
    return newLineString;
  }
  return visited;
}

function visitMultiLineString(
  multiLineString: MultiLineString,
  visitors: Visitors
) {
  const newMultiLineString = {
    ...multiLineString,
    coordinates: multiLineString.coordinates.map((group) =>
      group
        .map((position) => visitPosition(position, visitors))
        .filter(isNotNull)
    ),
  };
  const visited = visitors.multiLineString(newMultiLineString);
  if (visited === null) {
    return null;
  }
  if (!visited) {
    return newMultiLineString;
  }
  return visited;
}

function visitMultiPoint(multiPoint: MultiPoint, visitors: Visitors) {
  const newMultiPoint = {
    ...multiPoint,
    coordinates: multiPoint.coordinates
      .map((p) => visitPosition(p, visitors))
      .filter(isNotNull),
  };
  const visited = visitors.multiPoint(newMultiPoint);
  if (visited === null) {
    return null;
  }
  if (!visited) {
    return newMultiPoint;
  }
  return visited;
}

function visitMultiPolygon(multiPolygon: MultiPolygon, visitors: Visitors) {
  const newMultiPolygon = {
    ...multiPolygon,
    coordinates: multiPolygon.coordinates.map((positionGroups) =>
      positionGroups.map((positions) =>
        positions
          .map((position) => visitPosition(position, visitors))
          .filter(isNotNull)
      )
    ),
  };
  const visited = visitors.multiPolygon(newMultiPolygon);
  if (visited === null) {
    return null;
  }
  if (!visited) {
    return newMultiPolygon;
  }
  return visited;
}

function visitPolygon(polygon: Polygon, visitors: Visitors) {
  const newPolygon = {
    ...polygon,
    coordinates: polygon.coordinates.map((positions) =>
      positions
        .map((position) => visitPosition(position, visitors))
        .filter(isNotNull)
    ),
  };
  const visited = visitors.polygon(newPolygon);
  if (visited === null) {
    return null;
  }
  if (!visited) {
    return newPolygon;
  }
  return visited;
}

function visitPoint(point: Point, visitors: Visitors) {
  const coordinates = visitPosition(point.coordinates, visitors);
  if (!coordinates) {
    return point;
  }
  const newPoint = { ...point, coordinates };
  if (!newPoint.coordinates) {
    return null;
  }
  const visited = visitors.point(newPoint);
  if (visited === null) {
    return null;
  }
  if (!visited) {
    return newPoint;
  }
  return visited;
}

function visitPosition(position: Position, visitors: Visitors) {
  const visited = visitors.position(position);
  if (visited === null) {
    return null;
  }
  if (!visited) {
    return position;
  }
  return visited;
}
