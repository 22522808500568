import { RuleData, RuleOutput } from ".";
import { visit } from "../visit";

export function featuresHaveIds({ featureCollection }: RuleData): RuleOutput {
  const errors: string[] = [];
  visit(featureCollection, {
    feature: (feature) => {
      const id = feature.id;
      if (id !== undefined && id !== null) {
        return; // OK
      }
      const type = feature.properties?.type;
      if (!type || !typesThatRequireId.includes(type)) {
        return;
      }
      errors.push(`A feature with type="${type}" is missing and ID`);
    },
  });

  return { errors };
}

const typesThatRequireId = [
  "area",
  "area.floor",
  "area.group",
  "area.room",
  "area.room.bathroom",
  "area.room.conference",
  "area.hallway",
  "area.section",
  // "area.inaccessible",

  // "fixture.door",
  // "fixture.elevator",
  // "fixture.stairs",
  // "fixture.step",
  // "fixture.wall",
  // "fixture.wall.partition",
  // "fixture.tv",

  "furniture.chair",
  "furniture.desk",
  "furniture.counter",
  "furniture.table",

  "poi.printer",
  "poi.entrance",

  "poi.device.sensor",
];
