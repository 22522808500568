import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Index } from "./pages";
import { Dissolve } from "./pages/dissolve";
import { Fabege } from "./pages/fabege";
import { Pythagoras } from "./pages/pythagoras";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/fabege" element={<Fabege />} />
          <Route path="/pythagoras" element={<Pythagoras />} />
          <Route path="/dissolve" element={<Dissolve />} />
          <Route path="*" element={<Index />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
