import { FeatureCollection } from "geojson";
import { coordinatesWithinReasonableLimits } from "./coordinates-within-reasonable-limits";
import { featuresHaveIds } from "./features-have-ids";
import { idsAreUnique } from "./ids-are-unique";
import { idsShouldNotBeEmptyStrings } from "./ids-should-not-be-empty-strings";
import { namesShouldNotBeEmptyStrings } from "./names-should-not-be-empty-strings";
import { poiIsPoint } from "./poi-is-point";
import { propertiesTypeExists } from "./properties-type-exists";
import { propertiesTypeValid } from "./properties-type-valid";

export const rules: Rule[] = [
  {
    id: "coordinates-within-limits",
    name: "Coordinates within limits",
    execute: coordinatesWithinReasonableLimits,
  },
  {
    id: "names-should-not-be-empty-strings",
    name: "Names should not be empty strings",
    execute: namesShouldNotBeEmptyStrings,
  },
  {
    id: "ids-should-not-be-empty-strings",
    name: "IDs should not be empty strings",
    execute: idsShouldNotBeEmptyStrings,
  },
  {
    id: "features-have-ids",
    name: "Features of certain types have stable IDs",
    execute: featuresHaveIds,
  },
  {
    id: "ids-are-unique",
    name: "Every feature ID is unique",
    execute: idsAreUnique,
  },
  {
    id: "poi-is-point",
    name: "Point of Interest features are GeoJSON Points",
    execute: poiIsPoint,
  },
  {
    id: "properties-type-exists",
    name: 'Every feature has a "type"',
    execute: propertiesTypeExists,
  },
  {
    id: "properties-type-valid",
    name: 'Every "type" property is valid',
    execute: propertiesTypeValid,
  },
];

export type Rule = { id: string; name: string; execute: RuleExecutor };

export type RuleExecutor = (args: RuleData) => RuleOutput;

export type RuleData = {
  featureCollection: FeatureCollection;
};

export type RuleOutput = {
  errors?: string[];
};
