import { RuleData, RuleOutput } from ".";
import { visit } from "../visit";

export function coordinatesWithinReasonableLimits({
  featureCollection,
}: RuleData): RuleOutput {
  const errors: string[] = [];
  visit(featureCollection, {
    position: ([longitude, latitude]) => {
      if (longitude > 180 || longitude < -180) {
        errors.push(
          `Longitude ${longitude} is out of range. Please use WGS 84 and EPSG:4326. Example: 59.366 (Stockholm).`
        );
      }
      if (latitude > 180 || latitude < -180) {
        errors.push(
          `Latitude ${latitude} is out of range. Please use WGS 84 and EPSG:4326. Example: 18.012 (Stockholm).`
        );
      }
    },
  });

  return { errors };
}
