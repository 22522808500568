import {
  Box,
  Checkbox,
  Code,
  Container,
  FormControl,
  HStack,
  Stack,
} from "@chakra-ui/react";
import { useRect } from "@reach/rect";
import bboxPolygon from "@turf/bbox-polygon";
import { GeoJSONFeature, Map, MapBoxLayer } from "@yanzi/react-maps";
import { Feature, FeatureCollection } from "geojson";
import { ReactNode, useMemo, useRef, useState } from "react";

export function MapPreview({
  featureCollection,
  bbox,
  mapChildren,
}: {
  bbox: [number, number, number, number] | null;
  featureCollection: FeatureCollection;
  mapChildren?: ReactNode;
}) {
  const [colored, setColored] = useState(false);
  const [showNames, setShowNames] = useState(false);
  const bboxPoly = useMemo(() => {
    try {
      return bbox ? bboxPolygon(bbox) : null;
    } catch {
      return null;
    }
  }, [bbox]);
  const ref = useRef<HTMLDivElement>(null);
  const rect = useRect(ref);
  const sum = (rect?.width ?? 0) + (rect?.height ?? 0);
  const [selectedFeature, setSelectedFeature] = useState<null | Feature>(null);

  return (
    <Box minH="70vh" width="100%">
      <Stack width="100%">
        <Container maxW="container.xl">
          <HStack>
            <FormControl>
              <Checkbox
                isChecked={showNames}
                onChange={(e) => setShowNames(e.target.checked)}
              >
                Show names in preview
              </Checkbox>
            </FormControl>
            <FormControl>
              <Checkbox
                isChecked={colored}
                onChange={(e) => setColored((old) => e.target.checked)}
              >
                Color features by type
              </Checkbox>
            </FormControl>
          </HStack>
        </Container>
        <Stack direction="row" w="100%" height="50vh">
          <Box position="relative" backgroundColor="gray.50" flex={1} ref={ref}>
            <Map zoomLevel={21}>
              <MapBoxLayer zIndex={0} />
              {mapChildren}
              {bboxPoly ? (
                <GeoJSONFeature
                  key={sum}
                  zIndex={1}
                  autoCenter
                  feature={bboxPoly}
                />
              ) : null}

              {featureCollection.features.map((feature, i) => {
                const type = feature.properties?.type;
                if (
                  typeof type !== "string" ||
                  !["furniture", "fixture", "area", "floor", "building"].some(
                    (prefix) => type.startsWith(prefix)
                  )
                ) {
                  return (
                    <GeoJSONFeature
                      key={i}
                      feature={feature}
                      zIndex={2}
                      backgroundColor="#d00"
                      borderColor={
                        selectedFeature === feature ? "#0bf" : "#f99"
                      }
                      borderWidth={selectedFeature === feature ? 1 : 5}
                      onSelect={() => setSelectedFeature(feature)}
                      isSelected={selectedFeature === feature}
                      _hover={{ backgroundColor: "#fb0" }}
                    />
                  );
                }
                return (
                  <GeoJSONFeature
                    key={i}
                    feature={feature}
                    zIndex={2}
                    backgroundColor={
                      colored ? backgroundForFeature(feature) : undefined
                    }
                    borderColor={
                      selectedFeature === feature ? "#0bf" : undefined
                    }
                    borderWidth={selectedFeature === feature ? 5 : undefined}
                    onSelect={() => setSelectedFeature(feature)}
                    isSelected={selectedFeature === feature}
                    _hover={{ backgroundColor: "#fb0" }}
                  />
                );
              })}

              {showNames
                ? featureCollection.features.map((feature, i) => {
                    const name = feature.properties?.name;
                    if (name) {
                      return (
                        <GeoJSONFeature
                          zIndex={3}
                          key={feature.id ?? i}
                          feature={feature}
                          text={name}
                          textBackgroundColor="#fb0"
                          color="#000"
                          backgroundColor="transparent"
                          borderColor="transparent"
                          shape="circle"
                        />
                      );
                    }
                    return null;
                  })
                : null}
            </Map>
          </Box>
          {colored ? (
            <Stack>
              {colors.map(({ color, prefix }) => (
                <HStack>
                  <Box height="2rem" width="2rem" background={color} />
                  <Box>{prefix}</Box>
                </HStack>
              ))}
            </Stack>
          ) : null}
        </Stack>
        {selectedFeature ? (
          <Code as="pre" p={4}>
            {JSON.stringify(selectedFeature, null, 4)}
          </Code>
        ) : null}
      </Stack>
    </Box>
  );
}

function backgroundForFeature(feature: Feature) {
  const type = feature.properties?.type;
  if (!(typeof type === "string")) {
    return "#f00";
  }
  for (const { prefix, color } of colors) {
    if (type.startsWith(prefix)) {
      return color;
    }
  }
  return undefined;
}

const colors = [
  { prefix: "furniture.chair", color: "#0bf" },
  { prefix: "furniture", color: "#0fb" },
  { prefix: "area.floor", color: "#fc5" },
  { prefix: "area.room.conference", color: "#3ff" },
  { prefix: "area.room", color: "#990" },
  { prefix: "area.hallway", color: "#5a0" },
  { prefix: "area", color: "#ff4" },
  { prefix: "fixture.wall", color: "#999" },
  { prefix: "fixture", color: "#666" },
];
