import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";
import bbox from "@turf/bbox";
import difference from "@turf/difference";
import { point } from "@turf/helpers";
import { coordEach } from "@turf/meta";
import transformRotate from "@turf/transform-rotate";
import transformTranslate from "@turf/transform-translate";
import { PointFeature } from "@yanzi/react-maps";
import { saveAs } from "file-saver";
import { Feature, FeatureCollection, MultiPolygon, Polygon } from "geojson";
import { cloneDeep, orderBy, partition } from "lodash";
import { useMemo, useState } from "react";
import { FeaturesSummary } from "../../components/features-summary";
import { JSONUpload } from "../../components/json-upload";
import { MapPreview } from "../../components/map-preview";
import { analyze } from "../../lib/analyze";
import { dissolve } from "../../lib/dissolve";
import { isNotNull } from "../../lib/is-not-null";

export function useFeatureCollection(data: string | null) {
  const res = useMemo(() => {
    try {
      return data ? analyze(data, { strictParsing: false }) : null;
    } catch {
      return null;
    }
  }, [data]);

  return {
    ...res?.featureCollection,
    type: "FeatureCollection",
    features: res?.featureCollection?.features ?? [],
  } as FeatureCollection;
}

export function Fabege() {
  const [wallsJson, setWallsJson] = useState<null | string>(null);
  const [roomsJson, setRoomsJson] = useState<null | string>(null);
  const [furnitureJson, setFurnitureJson] = useState<null | string>(null);
  const [longitudeString, setLongitudeString] = useState("18.011750");
  const [latitudeString, setLatitudeString] = useState("59.366611");
  const [angleString, setAngleString] = useState("53");

  const longitude = Number(longitudeString);
  const latitude = Number(latitudeString);
  const angle = Number(angleString);

  const wallsInput = useFeatureCollection(wallsJson);
  const roomsInput = useFeatureCollection(roomsJson);
  const furnitureInput = useFeatureCollection(furnitureJson);

  const processedFeatureCollection = useMemo<FeatureCollection>(() => {
    const initialFeatureCollection: FeatureCollection = {
      type: "FeatureCollection",
      features: [
        ...roomsInput.features,
        ...furnitureInput.features,
        ...wallsInput.features,
      ],
    };

    const transforms = [
      moveFeaturesWithOrigo({ longitude, latitude }),
      setTypeFromLayer,
      removeFeaturesWithoutType,
      dissolveFeatures("area.floor"),
      dissolveFeatures("fixture.wall"),
      removeOtherAreasFromHallway,
      rotate([longitude, latitude], angle),
      sortFeatures,
      convertNullStringToUndefined,
    ];

    return transforms.reduce(
      (previous, transform) => transform(previous),
      initialFeatureCollection
    );
  }, [wallsInput, furnitureInput, roomsInput, longitude, latitude, angle]);

  const bboxFeature = useMemo(() => {
    try {
      return bbox(processedFeatureCollection) as any;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [processedFeatureCollection]);

  return (
    <Container maxW="container.xl" py={7}>
      <Stack spacing={5}>
        <FormControl>
          <FormLabel>Origo longitude</FormLabel>
          <Input
            type="text"
            value={longitudeString}
            onChange={(e) => setLongitudeString(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Origo latitude</FormLabel>
          <Input
            type="text"
            value={latitudeString}
            onChange={(e) => setLatitudeString(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Angle</FormLabel>
          <Input
            type="text"
            value={angleString}
            onChange={(e) => setAngleString(e.target.value)}
          />
        </FormControl>
        <h2>KARTA</h2>
        <JSONUpload onChange={(data) => setWallsJson(data)} />
        <h2>ROOM</h2>
        <JSONUpload onChange={(data) => setRoomsJson(data)} />
        <h2>INREDNING</h2>
        <JSONUpload onChange={(data) => setFurnitureJson(data)} />
        <Box>
          This tool will add all features from the first file above. It will
          then change the properties.Layer to properties.type. Then it will
          remove all remaining features that does not have a type. It will then
          dissolve all area.floor polygons and then dissolve all fixture.wall
          polygons. Lastly it will add all features from the second file.
        </Box>
        <Box>
          <Button
            colorScheme="green"
            isDisabled={!processedFeatureCollection.features.length}
            onClick={() =>
              saveAs(
                new Blob([JSON.stringify(processedFeatureCollection)]),
                "processed.json"
              )
            }
          >
            Download result
          </Button>
        </Box>
        {processedFeatureCollection ? (
          <MapPreview
            bbox={bboxFeature ?? null}
            featureCollection={processedFeatureCollection}
            mapChildren={
              <PointFeature
                coordinates={[Number(longitudeString), Number(latitudeString)]}
                shape="circle"
                borderColor="#0bf"
                borderWidth={2}
                backgroundColor="#4df"
                radius={10}
              />
            }
          />
        ) : null}
        {processedFeatureCollection ? (
          <FeaturesSummary featureCollection={processedFeatureCollection} />
        ) : null}
      </Stack>
    </Container>
  );
}

function setTypeFromLayer(inCollection: FeatureCollection): FeatureCollection {
  return {
    ...inCollection,
    features: inCollection.features.map((feature) => {
      const typeCandidate = feature.properties?.Layer;
      if (["area.floor", "fixture.wall"].includes(typeCandidate)) {
        return {
          ...feature,
          properties: { ...feature.properties, type: typeCandidate },
        };
      }
      return feature;
    }),
  };
}

function removeFeaturesWithoutType(
  inCollection: FeatureCollection
): FeatureCollection {
  return {
    ...inCollection,
    features: inCollection.features.filter((f) => !!f.properties?.type),
  };
}

function dissolveFeatures(type: string) {
  return (inCollection: FeatureCollection): FeatureCollection => {
    const [wallFeatures, otherFeatures] = partition(
      inCollection.features,
      (x): x is Feature<MultiPolygon> | Feature<Polygon> =>
        x.properties?.type === type &&
        (x.geometry.type === "MultiPolygon" || x.geometry.type === "Polygon")
    );

    if (!wallFeatures.length) {
      return inCollection;
    }

    const wallPolygons = wallFeatures.flatMap((feature): Feature<Polygon>[] => {
      if (feature.geometry.type === "MultiPolygon") {
        return feature.geometry.coordinates.map((coordinates) => ({
          type: "Feature" as const,
          geometry: { type: "Polygon" as const, coordinates },
          properties: null,
        }));
      }
      return [feature as Feature<Polygon>];
    });

    const res = dissolve({ type: "FeatureCollection", features: wallPolygons });

    return {
      ...inCollection,
      features: [
        ...otherFeatures,
        ...res.features.map((feature) => ({
          ...feature,
          properties: { ...feature.properties, type: type },
        })),
      ],
    };
  };
}

function moveFeaturesWithOrigo({
  latitude,
  longitude,
}: {
  longitude: number;
  latitude: number;
}) {
  const c = point([longitude, latitude]);

  const moveCoordinate = (inData: number[]) => {
    return transformTranslate(
      transformTranslate(c, inData[1] / 1000, 0, {
        units: "kilometers",
      }),
      inData[0] / 1000,
      90,
      { units: "kilometers" }
    ).geometry.coordinates;
  };

  return (inCollection: FeatureCollection): FeatureCollection => {
    const outCollection = cloneDeep(inCollection);
    coordEach(outCollection as any, (coord) => {
      const p = moveCoordinate(coord);
      coord.splice(0, 3, ...p.map((p) => Number(p.toFixed(7)))); // Remove also height property
    });

    return outCollection;
  };
}

function rotate(pivot: [number, number], deg: number) {
  return (x: FeatureCollection) => {
    return transformRotate(x as any, deg, { pivot });
  };
}

function removeOtherAreasFromHallway(featureCollection: FeatureCollection) {
  const [hallwayFeatures, otherFeatures] = partition(
    featureCollection.features,
    (f) => f.properties?.type?.startsWith?.("area.hallway")
  );
  const otherAreaFeatures = otherFeatures.filter(
    (f) =>
      (f.properties?.type?.startsWith?.("area") &&
        !f.properties?.type?.startsWith?.("area.floor")) ||
      f.properties?.type?.startsWith("fixture")
  );
  const fixedHallwayFeatures = hallwayFeatures
    .map((hallwayFeature) => {
      let newFeature: Feature | null = cloneDeep(hallwayFeature);
      for (const otherAreaFeature of otherAreaFeatures) {
        if (
          newFeature &&
          (newFeature.geometry.type === "Polygon" ||
            newFeature.geometry.type === "MultiPolygon") &&
          (otherAreaFeature.geometry.type === "Polygon" ||
            otherAreaFeature.geometry.type === "MultiPolygon")
        ) {
          newFeature = difference(
            newFeature as Feature<Polygon> | Feature<MultiPolygon>,
            otherAreaFeature as Feature<Polygon> | Feature<MultiPolygon>
          );
        }
      }
      if (newFeature) {
        try {
          newFeature.properties = cloneDeep(hallwayFeature.properties);
          newFeature.id = cloneDeep(hallwayFeature.id);
        } catch (e) {
          console.error(e);
        }
      }
      return newFeature;
    })
    .filter(isNotNull);

  return {
    ...featureCollection,
    features: [...otherFeatures, ...fixedHallwayFeatures],
  };
}

function sortFeatures(featureCollection: FeatureCollection): FeatureCollection {
  return {
    ...featureCollection,
    features: orderBy([...featureCollection.features], (x) =>
      zIndexFromType(x.properties?.type)
    ),
  };
}

function zIndexFromType(type: unknown) {
  if (!(typeof type === "string")) {
    return 0;
  }
  if (type.startsWith("area.floor")) {
    return 1;
  }
  if (type.startsWith("area")) {
    return 2;
  }
  if (type.startsWith("fixture")) {
    return 3;
  }
  if (type.startsWith("furniture")) {
    return 4;
  }
}

function convertNullStringToUndefined(
  featureCollection: FeatureCollection
): FeatureCollection {
  return {
    ...featureCollection,
    features: featureCollection.features.map((f) => ({
      ...f,
      id: f.id === "null" ? undefined : f.id,
      properties:
        f.properties && typeof f.properties === "object"
          ? {
              ...f.properties,
              name:
                f.properties.name === "null" ? undefined : f.properties.name,
            }
          : f.properties,
    })),
  };
}
