import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Container,
  HStack,
  ListItem,
  Spacer,
  Stack,
  UnorderedList,
} from "@chakra-ui/react";
import { groupBy } from "lodash";
import { useMemo } from "react";
import { Rule, rules } from "../../lib/rules";

export function ErrorList({
  errors,
}: {
  errors?: { rule?: Rule; message: string }[];
}) {
  const grouped = useMemo(() => {
    return groupBy(errors, (error) => error.rule?.id);
  }, [errors]);

  const goodRules = useMemo(
    () => rules.filter(({ id }) => !grouped[id]),
    [grouped]
  );

  return (
    <Container maxW="container.xl">
      <Stack>
        {!!errors?.length ? (
          <>
            <Alert status="error">
              <AlertIcon />
              <AlertTitle>{errors.length} errors detected</AlertTitle>
            </Alert>
          </>
        ) : null}
        {!errors?.length ? (
          <Alert status="success">
            <AlertIcon />
            <AlertTitle>No errors detected</AlertTitle>
          </Alert>
        ) : null}

        <Accordion allowMultiple>
          {Object.entries(grouped).map(([groupId, errors]) => (
            <AccordionItem key={groupId}>
              <AccordionButton>
                <HStack w="100%">
                  <AccordionIcon />
                  <Box>Requirement: {errors[0]?.rule?.name ?? "General"}</Box>
                  <Spacer />
                  <Badge colorScheme="red">{errors.length} errors</Badge>
                </HStack>
              </AccordionButton>

              <AccordionPanel pb={4}>
                <Stack>
                  <UnorderedList px={6} fontSize="sm" overflowX="auto">
                    {errors?.slice(0, 100).map((error, i) => (
                      <ListItem key={i}>
                        <pre>{error.message}</pre>
                      </ListItem>
                    ))}
                  </UnorderedList>
                  {errors.length > 100 ? (
                    <Alert status="warning">
                      <AlertIcon />
                      <AlertTitle>Too many errors</AlertTitle>
                      <AlertDescription>
                        Only the first 100 errors are shown
                      </AlertDescription>
                    </Alert>
                  ) : null}
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          ))}
          {goodRules.map((rule) => (
            <AccordionItem key={rule.id}>
              <AccordionButton>
                <HStack w="100%">
                  <AccordionIcon visibility="hidden" />
                  <Box>Requirement: {rule.name}</Box>
                  <Spacer />
                  <Badge colorScheme="green">Requirement met</Badge>
                </HStack>
              </AccordionButton>
            </AccordionItem>
          ))}
        </Accordion>
      </Stack>
    </Container>
  );
}
