import { Grid, Heading, Stack } from "@chakra-ui/react";
import bbox from "@turf/bbox";
import dissolve from "@turf/dissolve";
import { Feature, FeatureCollection, MultiPolygon, Polygon } from "geojson";
import { isEqual, partition } from "lodash";
import { useMemo, useState } from "react";
import { FeaturesSummary } from "../../components/features-summary";
import { JSONUpload } from "../../components/json-upload";
import { MapPreview } from "../../components/map-preview";
import { analyze } from "../../lib/analyze";

export function Dissolve() {
  const [json, setJson] = useState<null | string>(null);

  const res = useMemo(() => {
    try {
      return json ? analyze(json, { strictParsing: false }) : null;
    } catch {
      return null;
    }
  }, [json]);

  const inputFeatureCollection = res?.featureCollection;

  const processedFeatureCollection = useMemo(() => {
    return inputFeatureCollection
      ? dissolveSimilarFeatures(inputFeatureCollection)
      : null;
  }, [inputFeatureCollection]);

  const bboxFeature = useMemo(() => {
    try {
      return bbox(processedFeatureCollection) as any;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [processedFeatureCollection]);

  return (
    <>
      <JSONUpload onChange={(data) => setJson(data)} />
      <Grid
        p={8}
        gap={8}
        templateColumns="minmax(0, 1fr) minmax(0, 1fr)"
        width="100%"
        minHeight="70vh"
      >
        <Stack>
          <Heading>Before</Heading>
          {inputFeatureCollection ? (
            <MapPreview
              bbox={bboxFeature ?? null}
              featureCollection={inputFeatureCollection}
            />
          ) : null}
          {inputFeatureCollection ? (
            <FeaturesSummary featureCollection={inputFeatureCollection} />
          ) : null}
        </Stack>

        <Stack>
          <Heading>After</Heading>
          {processedFeatureCollection ? (
            <MapPreview
              bbox={bboxFeature ?? null}
              featureCollection={processedFeatureCollection}
            />
          ) : null}
          {processedFeatureCollection ? (
            <FeaturesSummary featureCollection={processedFeatureCollection} />
          ) : null}
        </Stack>
      </Grid>
    </>
  );
}

function dissolveSimilarFeatures(
  featureCollection: FeatureCollection
): FeatureCollection {
  const newFeatureCollection: FeatureCollection = {
    ...featureCollection,
    features: [],
  };

  const visitedFeatures: Feature[] = [];

  for (const feature of featureCollection.features) {
    if (visitedFeatures.length === 0) {
      visitedFeatures.push(feature);
      continue;
    }

    const lastVisited = visitedFeatures[visitedFeatures.length - 1];
    if (featuresAreInSameGroup(lastVisited, feature)) {
      visitedFeatures.push(feature);
      continue;
    }

    // Otherwise, dissolve visited and start over
    const res = dissolveFeatures({
      type: "FeatureCollection",
      features: visitedFeatures,
    });
    newFeatureCollection.features.push(
      ...res.features.map((f) => ({ ...f, properties: lastVisited.properties }))
    );
    visitedFeatures.splice(0, visitedFeatures.length, feature);
  }

  // If anything remaining
  const res = dissolveFeatures({
    type: "FeatureCollection",
    features: visitedFeatures,
  });
  const lastVisited = visitedFeatures[visitedFeatures.length - 1];
  newFeatureCollection.features.push(
    ...res.features.map((f) => ({ ...f, properties: lastVisited?.properties }))
  );

  return newFeatureCollection;
}

function featuresAreInSameGroup(a: Feature, b: Feature) {
  const x = { id: a.id, properties: a.properties };
  const y = { id: b.id, properties: b.properties };
  const sameGroup = isEqual(x, y);
  return sameGroup;
}

function dissolveFeatures(featureCollection: FeatureCollection) {
  const [almostDissolvableFeatures, otherFeatures] = partition(
    featureCollection.features,
    (feature): feature is Feature<Polygon> | Feature<MultiPolygon> =>
      feature.geometry.type === "Polygon" ||
      feature.geometry.type === "MultiPolygon"
  );

  const dissolvableFeatures = almostDissolvableFeatures.flatMap(
    (feature): Feature<Polygon>[] => {
      if (feature.geometry.type === "MultiPolygon") {
        return feature.geometry.coordinates.map((coordinates) => ({
          type: "Feature" as const,
          geometry: { type: "Polygon" as const, coordinates },
          properties: null,
        }));
      }
      return [feature as Feature<Polygon>];
    }
  );

  try {
    const res = dissolve({
      type: "FeatureCollection",
      features: dissolvableFeatures,
    });

    return {
      ...featureCollection,
      features: [...otherFeatures, ...res.features],
    };
  } catch {
    return featureCollection;
  }
}
