import flatten from "@turf/flatten";
import { multiPolygon } from "@turf/helpers";
import { coordEach } from "@turf/meta";
import { FeatureCollection, Polygon } from "geojson";
import { chunk } from "lodash";
import polygonClipping from "polygon-clipping";

export function dissolve(fc: FeatureCollection<Polygon>) {
  const bulks = chunk(fc.features, 200);
  const resFeatures = [];
  for (const bulk of bulks) {
    try {
      const res = flatten(
        multiPolygon(
          polygonClipping.union.apply(
            null,
            bulk.map((f) => {
              return f.geometry.coordinates.map((pos) =>
                pos.map((p) =>
                  p.map((xy) => Math.round(xy * round * 1000) / 1000)
                )
              );
            }) as any
          )
        )
      );

      coordEach(res, (coord) => {
        const p = [coord[0] / round, coord[1] / round];
        coord.splice(0, 2, ...p);
      });

      resFeatures.push(...res.features);
    } catch (e) {
      try {
        const res = flatten(
          multiPolygon(
            bulk.map((f) => {
              return f.geometry.coordinates;
            })
          )
        );
        resFeatures.push(...res.features);
      } catch (e) {
        console.error(e);
        resFeatures.push(...bulk);
      }
    }
  }

  return { type: "FeatureCollection", features: resFeatures };
}

const round = 100000;
