import bbox from "@turf/bbox";
import { FeatureCollection } from "geojson";
import { parseFeatureCollection } from "./parse-feature-collection";
import { Rule, rules } from "./rules";

export function analyze(
  fileContents: string,
  { strictParsing }: { strictParsing?: boolean }
) {
  const { data: jsonData, errors: jsonErrors } = tryParseJson(fileContents);
  if (jsonErrors) {
    return { errors: jsonErrors };
  }

  const res = parseFeatureCollection(jsonData, strictParsing);
  if ("errors" in res) {
    return { errors: res.errors.map((message) => ({ message })) };
  }
  const featureCollection = res.data;

  const { errors } = rules.reduce(
    ({ errors }, rule) => {
      const output = rule.execute({ featureCollection });
      return {
        errors: [
          ...errors,
          ...(output.errors ?? []).map((message) => ({
            rule,
            message,
          })),
        ],
      };
    },
    { errors: [] as { message: string; rule: Rule }[] }
  );

  const bbox = tryGetBBox(featureCollection);

  return {
    featureCollection,
    bbox,
    errors: errors.length ? errors : undefined,
  };
}

function tryGetBBox(featureCollection: FeatureCollection) {
  try {
    return (
      (bbox(featureCollection) as [number, number, number, number]) || null
    );
  } catch {
    return null;
  }
}

function tryParseJson(data: string) {
  try {
    return { data: JSON.parse(data) as unknown };
  } catch {
    return { data: null, errors: [{ message: "Failed to parse JSON" }] };
  }
}
