import { Badge, Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { FeatureCollection } from "geojson";
import { orderBy } from "lodash";
import { useMemo } from "react";

export function FeatureTable({
  featureCollection,
}: {
  featureCollection: FeatureCollection;
}) {
  const sortedFeatures = useMemo(() => {
    return featureCollection?.features
      ? orderBy(featureCollection.features, [
          (feature) => feature.properties?.name,
          (feature) => feature.properties?.type,
        ])
      : null;
  }, [featureCollection]);

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th>Feature ID</Th>
          <Th>Name</Th>
          <Th>GeoJSON type</Th>
          <Th>properties.type</Th>
          <Th>Address</Th>
          <Th>Parent reference</Th>
        </Tr>
      </Thead>
      <Tbody>
        {sortedFeatures?.map((feature, i) => {
          return (
            <Tr key={feature.id ?? i}>
              <Td>
                {!feature.id ? (
                  <Box as="span" color="red.500">
                    No ID
                  </Box>
                ) : (
                  feature.id
                )}
                <Badge ml={2}>{typeof feature.id}</Badge>
              </Td>
              <Td>{feature?.properties?.name}</Td>
              <Td>{feature?.geometry.type}</Td>
              <Td>
                {!feature.properties?.type ? (
                  <Box as="span" color="red.500">
                    No type
                  </Box>
                ) : (
                  feature.properties.type
                )}
              </Td>
              <Td>{feature?.properties?.address}</Td>
              <Td>{feature?.properties?.parent}</Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}
