import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Container,
  Heading,
  Stack,
} from "@chakra-ui/react";
import { saveAs } from "file-saver";
import { isEqual } from "lodash";
import { useMemo, useState } from "react";
import ReactDiffViewer from "react-diff-viewer";
import { JSONUpload } from "../../components/json-upload";
import { parseFeatureCollection } from "../../lib/parse-feature-collection";
import { fixPythagorasGeometry } from "../../utils/pythagoras";

export function Pythagoras() {
  const [json, setJson] = useState<null | string>(null);
  const featureCollectionData = useMemo(
    () => (json ? parseFeatureCollection(JSON.parse(json), false) : null),
    [json]
  );
  const featureCollection =
    featureCollectionData && "data" in featureCollectionData
      ? featureCollectionData.data
      : null;

  const processed = useMemo(() => {
    if (!featureCollection) {
      console.log("No featurecollection");
      return null;
    }

    console.log("Processing", featureCollection);

    const fixes = featureCollection.features.map((feature) => {
      const type = feature.properties?.type;
      if (!["furniture.desk", "furniture.chair"].includes(type)) {
        return { fixed: false, result: feature, previous: feature };
      }

      const fixedGeometry = fixPythagorasGeometry(feature.geometry);
      if (
        fixedGeometry === feature.geometry ||
        isEqual(fixedGeometry, feature.geometry)
      ) {
        return { fixed: false, result: feature, previous: feature };
      } else {
        return {
          fixed: true,
          previous: feature,
          result: { ...feature, geometry: fixedGeometry },
        };
      }
    });

    return {
      fixedFeatures: fixes
        .filter((fix) => fix.fixed)
        .map((f) => ({ result: f.result, previous: f.previous })),
      result: {
        ...featureCollection,
        features: fixes.map((f) => f.result),
      },
    };
  }, [featureCollection]);

  const [showDiff, setShowDiff] = useState(false);

  return (
    <Container maxW="container.xl" py={7}>
      <Stack spacing={5}>
        <Heading as="h1">Fix incorrect LineString desks and chairs</Heading>
        <Box>
          This tool will convert features from LineStrings to Geometries. It
          might be a bit slow, it's normal.
        </Box>
        <JSONUpload onChange={(data) => setJson(data)} />
        {json ? <Box>File uploaded</Box> : null}
        <Button onClick={() => setShowDiff((old) => !old)}>
          {showDiff
            ? "Hide diff"
            : `Show diff (${processed?.fixedFeatures.length ?? 0})`}
        </Button>
        {showDiff ? (
          <Accordion>
            {processed?.fixedFeatures.map(({ result, previous }, i) => {
              return (
                <AccordionItem key={i}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton>Fix {i}</AccordionButton>
                      <AccordionPanel>
                        {isExpanded ? (
                          <Diff
                            oldValue={JSON.stringify(previous, null, 4)}
                            newValue={JSON.stringify(result, null, 4)}
                          />
                        ) : null}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              );
            })}
          </Accordion>
        ) : null}
        <Box>
          <Button
            colorScheme="green"
            isDisabled={!processed?.result.features?.length}
            onClick={() =>
              saveAs(
                new Blob([JSON.stringify(processed?.result)]),
                "processed.json"
              )
            }
          >
            Download result
          </Button>
        </Box>
      </Stack>
    </Container>
  );
}

function Diff({ newValue, oldValue }: { oldValue: string; newValue: string }) {
  return <ReactDiffViewer oldValue={oldValue} newValue={newValue} />;
}
