import { Container, Heading, Stack } from "@chakra-ui/react";
import { FeatureCollection } from "geojson";
import { FeatureTable } from "../feature-table";
import { FeatureTypeTable } from "../feature-type-table";
import { GeometryTypeTable } from "../geometry-type-table";

export function FeaturesSummary({
  featureCollection,
}: {
  featureCollection: FeatureCollection;
}) {
  return (
    <Container maxW="container.xl">
      <Stack spacing={5}>
        <Heading as="h2" fontSize="lg">
          By type
        </Heading>
        <FeatureTypeTable featureCollection={featureCollection} />
        <Heading as="h2" fontSize="lg">
          By geometry
        </Heading>
        <GeometryTypeTable featureCollection={featureCollection} />
        <Heading as="h2" fontSize="lg">
          All features
        </Heading>
        <FeatureTable featureCollection={featureCollection} />
      </Stack>
    </Container>
  );
}
