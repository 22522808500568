import {
  Badge,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Heading,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Wrap,
} from "@chakra-ui/react";
import { forwardRef, useMemo, useState } from "react";
import { ErrorList } from "../components/error-list";
import { FeaturesSummary } from "../components/features-summary";
import { JSONUpload } from "../components/json-upload";
import { MapPreview } from "../components/map-preview";
import { analyze } from "../lib/analyze";

export function Index() {
  const [json, setJson] = useState<null | string>(null);
  const [strictParsing, setStrictParsing] = useState(true);
  const result = useMemo(() => {
    if (json) {
      try {
        return analyze(json, { strictParsing });
      } catch (e: any) {
        return {
          bbox: null,
          featureCollection: undefined,
          errors: [{ message: "message" in e ? e.message : JSON.stringify(e) }],
        };
      }
    } else {
      return null;
    }
  }, [json, strictParsing]);

  const featureCollection = result?.featureCollection;

  return (
    <Box>
      <Container py={6} maxW="container.xl">
        <Stack>
          <Wrap>
            <Heading as="h1">Yanzi GeoJSON Validator</Heading>
            <Spacer />
            <Box>
              <Button
                as={forwardRef<HTMLAnchorElement>((props, ref) => (
                  <a
                    ref={ref}
                    href="https://yanzi.dev/developer/building-model"
                    target="_blank"
                    rel="noreferrer noopener"
                    {...props}
                  >
                    {props.children}
                  </a>
                ))}
                colorScheme="green"
              >
                Yanzi GeoJSON Specification
              </Button>
            </Box>
            <Box>
              <Button
                as={forwardRef<HTMLAnchorElement>((props, ref) => (
                  <a download href="/sample.json" {...props} ref={ref}>
                    {props.children}
                  </a>
                ))}
              >
                Download sample GeoJSON
              </Button>
            </Box>
          </Wrap>
          <JSONUpload onChange={(data) => setJson(data)} />
          <FormControl>
            <Checkbox
              isChecked={strictParsing}
              onChange={(e) => setStrictParsing(e.target.checked)}
            >
              Strict parsing
            </Checkbox>
          </FormControl>
        </Stack>
      </Container>

      <Tabs>
        <Container maxW="container.xl">
          <TabList>
            <Tab isDisabled={!result}>
              Errors
              {result?.errors?.length !== undefined ? (
                <Badge
                  ml={2}
                  colorScheme={result.errors.length ? "red" : "green"}
                >
                  {result?.errors?.length}
                </Badge>
              ) : null}
            </Tab>
            <Tab isDisabled={!featureCollection}>Map preview</Tab>
            <Tab isDisabled={!featureCollection}>
              Features
              {result?.featureCollection?.features ? (
                <Badge ml={2} colorScheme="blue">
                  {result.featureCollection.features.length}
                </Badge>
              ) : null}
            </Tab>
          </TabList>
        </Container>

        <TabPanels>
          <TabPanel>
            {result ? <ErrorList errors={result?.errors} /> : null}
          </TabPanel>
          <TabPanel>
            {featureCollection ? (
              <MapPreview
                bbox={result?.bbox ?? null}
                featureCollection={featureCollection}
              />
            ) : null}
          </TabPanel>
          <TabPanel>
            {featureCollection ? (
              <FeaturesSummary featureCollection={featureCollection} />
            ) : null}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
